// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import { Carousel } from "bootstrap"

// require('./custom/jquery');
require('./custom/owl-carousel');
//require('./custom/animation');
//require('./custom/imagesloaded');
require('./custom/templatemo-custom');
